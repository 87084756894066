export default (to, from, savedPosition) =>
  new Promise(resolve => {
    // TODO: Spec
    setTimeout(() => {
      if (to?.hash || from?.hash) return resolve({}) // Modal open / close
      if (to?.path === from?.path) return resolve({}) // Same page
      if (to && to.query?.download) return resolve({}) // Don't change scroll pos
      if (savedPosition) return resolve(savedPosition)

      return resolve({ x: 0, y: 1 }) // 1px to prevent iOS from showing Safari window
    }, 200)
  })
